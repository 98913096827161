import React from "react"
import { Link } from "gatsby"

import { NavigationWrapper, ContactUsButton } from "./Navigation.styles"

export default () => {
  return (
    <NavigationWrapper>
      <Link to="/">
        <img
          alt="logo"
          className="logo"
          src={require("../../images/logo.svg")}
        />
      </Link>
      <Link to="/contact">
        <ContactUsButton>
          <span className="desktop">Contact Us</span>
          <span className="mobile">&#9742;</span>
        </ContactUsButton>
      </Link>
    </NavigationWrapper>
  )
}
