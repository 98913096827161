import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { Button } from "antd"

export const HeaderWrapper = styled.header`
  background: #0b0314;
`

export const HeaderContentWrapper = styled(BackgroundImage)`
  height: 100vh;
  position: relative;
  background-position: fixed;
  background-size: cover;

  @media screen and (max-width: 1320px) {
    height: auto;
    position: static;
  }
`
export const MainHeaderContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  padding: 130px 0;
  max-width: 1550px;
  display: grid;
  grid-template-columns: 620px 400px 400px;
  grid-gap: 45px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  img {
    width: 100%;
  }

  .header-icons {
    margin-top: 230px;
  }

  @media screen and (max-width: 1580px) {
    width: 100%;
    padding: 0 25px;
  }

  .content {
    color: white;
    margin-right: 45px;

    h1 {
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
      font-family: Raleway;
      font-size: 3.375rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: 0;
    }

    p {
      font-family: Raleway;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.4px;
      text-align: left;
      color: #ffffff;
      margin: 33px 0 56px;
    }
  }

  .buttons {
    display: grid;
    gap: 42px;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
  }

  @media screen and (max-width: 1580px) {
    grid-template-columns: 2fr 1fr 1fr;
    padding-left: 25px;
    padding-right: 25px;

    .content {
      padding-top: 50px;
      h1 {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 1320px) {
    position: static;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    /* text-align: center; */

    .buttons {
      justify-content: center;
    }

    .content {
      grid-column-end: span 2;
      margin: 0;
      margin-bottom: 25px;
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }

    .header-icons,
    .header-phone {
      /* display: none; */
    }
  }
`

export const ContactHeaderWrapper = styled.header`
  background: #0b0314;

  .wrapper {
    max-width: 1550px;
    margin: 0 auto;
    margin-top: 70px;
  }

  h1 {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);

    font-family: Raleway;

    font-size: 3.375rem;

    font-weight: bold;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.22;

    letter-spacing: normal;

    text-align: left;

    color: #ffffff;
    margin: 0;
    margin-bottom: 22px;
  }

  .triangle-content-wrapper {
    max-width: 1550px;
    margin: 0 auto;
    display: grid;
    justify-content: start;
    align-items: center;

    & > * {
      display: flex;
      align-items: center;
      .gatsby-image-wrapper {
        margin-right: 25px;
      }
    }
    gap: 55px;
    grid-auto-flow: column;

    font-family: Raleway;

    font-size: 1.125rem;

    font-weight: bold;

    font-stretch: normal;

    font-style: normal;

    line-height: 0.89;

    letter-spacing: normal;

    text-align: left;

    color: #ffffff;

    align-items: center;
  }
  @media screen and (max-width: 1580px) {
    .wrapper {
      padding-top: 50px;
    }
    .wrapper,
    .triangle-content-wrapper {
      padding: 0 25px;
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      width: 100%;
      padding: 0 25px;
    }

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    .triangle-content-wrapper {
      width: 100%;
      padding: 25px;
      grid-auto-flow: row;
      justify-content: center;
      gap: 10px;
    }
  }
`

export const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 620px 400px 400px;
  grid-gap: 45px;
  padding-bottom: 56px;
  max-width: 1550px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: end;

  @media screen and (max-width: 1580px) {
    width: 100%;
    padding: 0 25px;
  }

  ${ContactHeaderWrapper} & {
    padding-bottom: 28px;
  }

  .content {
    margin-bottom: 90px;
    color: white;
    margin-right: 45px;

    h1 {
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
      font-family: Raleway;
      font-size: 3.375rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: 0;
    }

    p {
      font-family: Raleway;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.4px;
      text-align: left;
      color: #ffffff;
      margin: 33px 0 56px;
    }
  }

  .buttons {
    display: grid;
    gap: 42px;
    grid-auto-flow: column;
    justify-content: start;
  }

  @media screen and (max-width: 1580px) {
    grid-template-columns: 2fr 1fr 1fr;
    padding-left: 25px;
    padding-right: 25px;

    .content {
      padding-top: 50px;
      h1 {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 1320px) {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    /* text-align: center; */

    .content {
      grid-column-end: span 2;
      margin: 0;
      margin-bottom: 25px;
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }

    .header-icons,
    .header-phone {
      /* display: none; */
    }
  }
`

export const ContactButton = styled(Button)`
  padding: 23px 48px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Raleway;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  line-height: 1;
  --box-shadow-color: #eb3bf1a0;
  box-shadow: 0 0 35px 0px var(--box-shadow-color);
  box-sizing: border-box;
  height: auto;
  background: ${props => props.theme.gradient("right")};
  border-radius: 33px;
  border: none;

  &:hover,
  &:focus {
    border: none;
    color: white;
    background: ${props => props.theme.gradient("right")};
    --box-shadow-color: #eb3bf1c0;
    filter: brightness(110%);
    transform: scale(105%);
  }
`

export const TweetUs = styled(Button)`
  padding: 0;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Raleway;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  line-height: 1;
  box-sizing: border-box;
  height: auto;

  &:hover,
  &:focus {
    color: white;
    filter: brightness(110%);
    transform: scale(105%);
    text-shadow: 0 0 10px #ffffff80;
  }
`

export const HeaderItems = styled.section`
  display: grid;
  max-width: 1320px;
  margin: 125px auto 38px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 100px;

  .header-item {
    display: grid;
    grid-template-rows: 62px auto auto;
    grid-template-columns: 1fr;
    gap: 25px;

    h4 {
      margin-bottom: 13px;
      font-family: Raleway;

      font-size: 1.5rem;

      font-weight: 600;

      font-stretch: normal;

      font-style: normal;

      line-height: 1.33;

      letter-spacing: normal;

      color: #ffffff;
    }

    p {
      font-family: Poppins;

      font-size: 0.938rem;

      font-weight: normal;

      font-stretch: normal;

      font-style: normal;

      line-height: 1.87;

      letter-spacing: normal;

      text-align: left;

      color: #8a7a9c;
    }
  }

  @media screen and (max-width: 1320px) {
    grid-template-columns: 1fr;
    padding: 25px;
    gap: 30px;
    margin: 0;
    margin-top: 20px;

    .header-item {
      gap: 5px;
      justify-content: center;

      .gatsby-image-wrapper {
        margin: 0 auto;
        text-align: center;
      }

      h4 {
        text-align: center;
      }
    }
  }
`

export const HeaderTriangle = styled.div`
  width: 100%;
  height: calc(calc(100vw / 9.6) + 1px);
  position: relative;

  .triangle {
    width: 100%;
    height: 100%;
    background: white;
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    position: absolute;
    top: 0;
  }

  @media screen and (max-width: 1024px) {
    &.hide-mobile {
      height: auto;

      .triangle {
        display: none;
      }
    }

    span {
      display: none;
    }
  }

  .wrapper {
    max-width: 1320px;
    margin: 0 auto;
    position: relative;
  }

  span {
    position: absolute;
    top: 0;
    transform: rotate(-0.1038rad) translateX(66px) translateY(15%);
    font-family: Raleway;
    font-size: calc(100vw / (1920 / (72 * 1.6)));
    line-height: 1;

    font-weight: bold;

    font-stretch: normal;

    font-style: normal;

    letter-spacing: normal;

    text-align: left;
    color: #160924;
    /* color: red; */
    filter: brightness(120%);
    /* z-index: 100; */
    transition: all 0.2s ease;
  }

  &:hover {
    span {
      filter: brightness(160%);
      /* text-shadow: 0 0 10px #eb3bf140; */
    }
  }
`
