import styled from "styled-components"
import { Button } from "antd"

export const NavigationWrapper = styled.nav`
  /* position: sticky; */
  top: 0;
  color: white;
  position: relative;
  z-index: 100;
  padding: 70px 60px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 215px;
  }

  @media screen and (max-width: 1550px) {
    padding: 25px;

    .logo {
      width: 160px;
    }
  }
`

export const ContactUsButton = styled(Button)`
  padding: 16px 30px;
  font-family: Raleway;
  font-size: 1.125rem;
  line-height: 1;
  border-radius: 26px;
  letter-spacing: 1px;
  border: solid 2px #b13688;
  box-sizing: border-box;
  color: white;
  height: auto;
  background: transparent;

  &:hover,
  &:focus {
    border-color: #b13688;
    color: white;
    background: #b1368840;
    transform: scale(105%);
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: initial;
    }
  }
`
